define('ember-data-change-tracker/model-ext', ['ember-data/model', 'ember-data-change-tracker/tracker'], function (_model, _tracker) {
  'use strict';

  _model.default.reopen({
    didChange: function didChange(key, changed, options) {
      return _tracker.default.didChange(this, key, changed, options);
    },
    changed: function changed() {
      var changed = Object.assign({}, this.changedAttributes());
      var trackerInfo = _tracker.default.metaInfo(this);
      for (var key in trackerInfo) {
        if (!changed[key] && trackerInfo.hasOwnProperty(key)) {
          if (this.didChange(key, changed)) {
            changed[key] = true;
          }
        }
      }
      return changed;
    },
    rollback: function rollback() {
      var trackerInfo = _tracker.default.metaInfo(this);
      this.rollbackAttributes();
      var rollbackData = _tracker.default.rollbackData(this, trackerInfo);
      var normalized = _tracker.default.normalize(this, rollbackData);
      this.store.push(normalized);
    },
    startTrack: function startTrack() {
      this.saveChanges();
    },
    saveChanges: function saveChanges() {
      _tracker.default.setupTracking(this);
      _tracker.default.triggerIsDirtyReset(this);
      _tracker.default.saveChanges(this);
    },
    savedTrackerValue: function savedTrackerValue(key) {
      return _tracker.default.lastValue(this, key);
    },


    // save state when model is loaded or created if using auto save
    setupTrackerMetaData: Ember.on('ready', function () {
      if (_tracker.default.isIsDirtyEnabled(this)) {
        // this is experimental
        _tracker.default.initializeDirtiness(this);
      }
      if (_tracker.default.isAutoSaveEnabled(this)) {
        _tracker.default.setupTracking(this);
        this.saveChanges();
      }
    }),

    // when model updates, update the tracked state if using auto save
    saveOnUpdate: Ember.on('didUpdate', function () {
      if (_tracker.default.isAutoSaveEnabled(this) || _tracker.default.isIsDirtyEnabled(this)) {
        this.saveChanges();
      }
    }),

    reload: function reload() {
      var _this = this;

      var promise = this._super();
      promise.then(function () {
        if (_tracker.default.isAutoSaveEnabled(_this)) {
          _this.saveChanges();
        }
      });
      return promise;
    },


    // when model deletes, remove any tracked state
    clearSavedAttributes: Ember.on('didDelete', function () {
      _tracker.default.clear(this);
    })

  });
});