define('ember-data-change-tracker/utilities', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var modelTransform = exports.modelTransform = function modelTransform(model, polymorphic) {
    if (polymorphic) {
      return { id: model.id, type: model.modelName || model.constructor.modelName };
    }
    return model.id;
  };

  var relationShipTransform = exports.relationShipTransform = {
    belongsTo: {
      serialize: function serialize(model, key, options) {
        var relationship = model.belongsTo(key).belongsToRelationship;
        var value = relationship.hasOwnProperty('inverseInternalModel') ? relationship.inverseInternalModel : relationship.inverseRecord;
        return value && modelTransform(value, options.polymorphic);
      },
      deserialize: function deserialize() {}
    },
    hasMany: {
      serialize: function serialize(model, key, options) {
        var value = model.hasMany(key).value();
        return value && value.map(function (item) {
          return modelTransform(item, options.polymorphic);
        });
      },
      deserialize: function deserialize() {}
    }
  };

  var isEmpty = exports.isEmpty = function isEmpty(value) {
    if (Ember.typeOf(value) === 'object') {
      return Ember.$.isEmptyObject(value);
    }
    return Ember.isEmpty(value);
  };

  var didSerializedModelChange = exports.didSerializedModelChange = function didSerializedModelChange(one, other, polymorphic) {
    if (polymorphic) {
      return one.id !== other.id || one.type !== other.type;
    }
    return one !== other;
  };

  var didModelsChange = exports.didModelsChange = function didModelsChange(one, other, polymorphic) {
    if (isEmpty(one) && isEmpty(other)) {
      return false;
    }

    if ((one && one.length) !== (other && other.length)) {
      return true;
    }

    for (var i = 0, len = one.length; i < len; i++) {
      if (didSerializedModelChange(one[i], other[i], polymorphic)) {
        return true;
      }
    }

    return false;
  };

  var didModelChange = exports.didModelChange = function didModelChange(one, other, polymorphic) {
    if (isEmpty(one) && isEmpty(other)) {
      return false;
    }

    if (!one && other || one && !other) {
      return true;
    }

    return didSerializedModelChange(one, other, polymorphic);
  };
});